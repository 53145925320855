/*Variáveis nomeadas de acordo com a escala de cinza em CMYK, o nome é atribuido de acordo com a porcentagem do K*/

// <link rel=""stylesheet"" href=""https://cloud.typography.com/6190156/6461372/css/fonts.css"">
@import url("https://cloud.typography.com/6190156/6461372/css/fonts.css");

/* -- GRAYS COLORS -- */
$gray-86: #242424; //(titulo)
$gray-78: #282828;
$gray-74: #434343;
$gray-70: #4c4c4c;
$gray-55: #727272;
$gray-39: #9c9a9a; //(msg dos i)
$gray-16: #d7d7d7; //(border de tabelas)
$gray-12: #e1e0e0; //(placeholder)
$gray-09: #e9e9e9; //(button default)
$gray-07: #ebebeb; //(border)
$gray-03: #f7f7f7; //(body)
$gray-01: #fcfbfc; //(input claro)

$white-00: #ffffff;

/* -- UI COLORS -- */
$green-32: #46ae70;
$purple-23: #9587c9;
$blue-18: #40b5d1; //(cards)
$blue-00: #00aeff; //(links)
$blue50: #50AFF4;
$red-09: #e85035;

/*-- MEDIA --*/
$break-small: 320px;
$break-large: 1024px;

/*-- FONTS --*/
// $font-book: "GothamNarrow-Book", Helvetica, Arial, sans-serif;
// $font-medium: "GothamNarrow-Medium", Helvetica, Arial, sans-serif;
// $font-black: "GothamNarrow-Black", Helvetica, Arial, sans-serif;
// $font-bold: "GothamNarrow-Bold", Helvetica, Arial, sans-serif;
$font-book:  'Poppins', sans-serif;
$font-medium: 'Poppins', sans-serif;
$font-black: 'Poppins', sans-serif;
$font-bold: 'Poppins', sans-serif;
