$--toastify-toast-min-height: 40px;

.Toastify__toast.tostify-custom-class {
  min-height: 54px;
  border-radius: 5px;
  padding: 15px;
}
.Toastify__toast--success {
  background-color: #5CBBAA;
}
.Toastify__toast--error {
  background-color: #EF5D5D;
}
.Toastify__progress-bar--success.custom-progress-bar {
  background-color: #49a897;
}

.Toastify__toast-body.tostify-custom-body {
  padding-left: 30px;
  position: relative;

  &:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    left: 6px;
    background: url('https://icons.alboompro.com/v1/bold/interface-essential/form-validation/ffffff/check-circle-1.svg') no-repeat;
  }
}

.Toastify__toast--error .Toastify__toast-body.tostify-custom-body {

  &:before {
    background: url('https://icons.alboompro.com/v1/bold/interface-essential/delete/ffffff/delete-2.svg');
  }
}