nav {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    box-shadow: 0px 14px 5px -10px rgba(0,0,0,0.03);
    z-index: 10;

    a.nav-link--font {
        //@mixin font-source($font, $size, $colour, $weight, $line-height:, $text-align)
        @include font-source($font-medium, 0.7em, #373636, 400, 1em, center);
    } 
    a.nav-link--font-user {
        //@mixin font-source($font, $size, $colour, $weight, $line-height:, $text-align)
        @include font-source($font-medium, 0.9em, #373636, 400, 1em, center);
        display: unset;
    }
    li.nav-item{
        @include respond-to(handhelds) { text-align: left; }
        @include respond-to(wide-screens) { text-align: center; }
    }
    li .icon{
        font-size: 1.3em;
    }
}