/* -- CUSTONS BUTTONS -- */
.buttonCard {
  @include button(transparent, $white-00);
  border: 1px solid $white-00;
  font-size: 1.2em;
}

/* -- UI BUTTOONS -- */
.buttonDefault {
  @include button($gray-09, $gray-78);
  &:hover {
    background: $gray-09;
  }
}
.buttonGreen {
  @include button($green-32, $white-00);
  background: $green-32 !important;
}
.buttonBlue {
  @include button($blue50, $white-00);
  background: $blue50 !important;
}
.buttonGreen > div > div {
  color: white !important;
}
.buttonBlue > div > div {
  color: white !important;
}
.buttonRed {
  @include button($red-09, $white-00);
}

/* -- Size Buttons -- */
.buttonSmall {
  width: 33%;
  min-height: 1.5em;
  font-size: 1.8em;
}
.buttonLarge {
  width: 100%;
  min-height: 1.5em;
  font-size: 1.8em;
}
