.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;

  user-select: none;

  .message {
    display: none;
    position: absolute;
    background: $white-00;
    color: $gray-39;
    // font-size: 1.2rem;
    min-width: 20rem;
    right: 0.5rem;
    top: 2rem;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
  }

  span.label {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 0;
    svg {
      margin-left: 0.6rem;
      // fill: $gray-86;
      // margin-bottom: 1rem;

      &:hover ~ div {
        display: block;
        z-index: 9;
        text-transform: none;
      }
    }
    > div {
      position: relative;
    }
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    height: 17px;
    width: 17px;
    border-radius: 2px;
    border: 1px solid #b1b1b1;
  }
  input:checked ~ .checkmark {
    background-color: $blue50;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    // margin-left: 3px;
    // top: 2px;
    // width: 6px;
    // height: 10px;
    // border: solid $white-00;
    // border-width: 0 2px 2px 0;
    // transform: rotate(45deg);
    margin-left: 4px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
  }
}
