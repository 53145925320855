.radio-custom {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  color: $gray-86;

  span.label {
    display: flex;
    align-items: center;
    position: relative;

    p {
      font-size: 2em;
    }
    svg {
      margin-left: 0.6rem;
      fill: $gray-86;

      &:hover ~ div {
        display: block;  
      }
    }
    > div {
      position: relative;
    }
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .radioContainer .checkmark:after {
    display: block;
  }
  .radioContainer {
    .checkmark {
      margin-right: 1rem;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid #bbb;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white-00;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    .checkmark:after {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $blue50;
    }
  }

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
