@keyframes fadeIn {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.sidebar {
  padding: 2em 1em;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: auto;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.03);

  @include respond-to(handhelds) {
    width: 82%;
  }
  @include respond-to(wide-screens) {
    width: 23%;
    // Para deixar a barra dinamica - tirar o display display: block!important;
    // display: block!important;
  }

  .bar-name {
    display: flex;
    p {
      @include font-source($font-medium, 1em, #373636, 400, 1em, center);
      padding-right: 0.5em;
    }
  }
  .bar-info {
    li {
      @include font-source($font-book, 0.9em, #373636, 400, 1em);
      margin-bottom: 0.6em;
    }
  }

  .bar-elements {
    padding: 0 1em;

    p {
      margin-bottom: 1em;
    }
    li {
      @include font-source($font-book, 1em, #373636, 400, 2em);
    }
  }
}

.menuBefore {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px !important;
  margin: 5px 5px 0 !important;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #f2f6f9;
    border-radius: 3px;
  }

  &:hover .userInfo a {
    display: block;
  }

  .userAvatar {
    position: relative;

    @media (max-width: 768px) {
      margin-left: -5px !important;
    }

    .noAvatar {
      display: flex;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #d467e5;
      font-size: 15px;
      justify-content: center;
      align-items: center;
      color: #ffffff;
    }

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #ffffff;
      object-fit: cover;

      @media (max-width: 768px) {
        & {
          width: auto;
          height: auto;
          max-width: 42px;
          max-height: 42px;
        }
      }
    }

    .badge {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 17px;
      height: 17px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:hover .accountStatus {
        cursor: default;

        @media (max-width: 768px) {
          & {
            display: none;
          }
        }
      }

      &:hover .fadeAnimation {
        animation-name: fadeIn;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
      }

      &:hover .noAnimation {
        display: block;
      }

      img {
        width: 20px;
        height: 17px;
      }

      img.verifiedIcon {
        background-color: #1b9dfa;
        width: 8px;
        height: 8px;
      }

      .closeButton {
        background-color: #1b9dfa;
        width: 12px;
        height: 12px;
        margin-left: 10px !important;

        :hover {
          background-color: #4bb1fa;
          cursor: pointer;
        }
      }

      span {
        display: block;
      }

      .fadeAnimation {
        animation-name: fadeOut;
        animation-delay: 4s;
        animation-duration: 100ms;
        animation-fill-mode: forwards;
      }

      .hidePopUp {
        display: none !important;
        visibility: hidden !important;
      }

      #popup {
        display: none;
      }

      .accountStatus {
        display: none;
        text-align: left;
        position: absolute;
        top: 26px;
        left: -34px;
        background-color: #1b9dfa;
        padding: 12px !important;
        border-radius: 2px;
        width: 206px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        color: #ffffff;
        white-space: normal;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -7px;
          left: 31px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #1b9dfa;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -15px;
          left: 0;
          width: 100%;
          height: 20px;
          background-color: transparent;
        }

        > div {
          display: flex;
          align-items: center;
        }

        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #1b9dfa;
        }

        span.verifiedSpan {
          background-color: #fff;
        }

        img {
          width: 12px;
          height: 12px;
          display: inline-block;
          background-color: #1b9dfa;
        }

        span.verifiedSpan img {
          width: 8px;
          height: 8px;
          background-color: #fff;
        }

        h3 {
          display: inline-block;
          font-size: 13px;
          color: #ffffff;
          font-weight: 500;
          line-height: 12px;
          margin-left: 5px !important;
        }

        p {
          margin-top: 8px !important;
          font-size: 12px;
          line-height: 130%;
          font-weight: 400;
        }

        a {
          display: block;
          text-decoration: underline;
          font-weight: 400;
          color: #ffffff;
          margin-top: 8px !important;
          font-size: 11px;
          line-height: 13.2px;
          cursor: pointer;
        }
      }
    }
  }

  .userInfo {
    margin-left: 10px !important;

    h3 {
      font-size: 15px;
      white-space: nowrap;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin-top: 4px !important;
      font-size: 11px;
    }

    a {
      display: none;
      position: absolute;
      bottom: 8px;
      right: 8px;

      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }

    @media (max-width: 768px) {
      & {
        display: none;
      }
    }
  }
}

.menuAfter {
  padding: 20px 15px !important;

  .menuAfter-Wrapper {
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 15px !important;
    display: flex;
  }

  .menuAfter-IconWrapper {
    margin-right: 10px !important;
    margin-top: -1px !important;
  }

  .menuAfter-textWrapper {
    a {
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      color: #212121;
    }
  }
}
