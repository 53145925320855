.optionsWebsite {
  display: flex;
  flex-wrap: wrap;
  margin: -25px 0px 45px;
  padding: 10px 0px;

  .own_website {
    order: 1;
  }
  .copyurl_website {
    order: 2;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    .own_website {
      order: 2;
    }
    .copyurl_website {
      order: 1;
      justify-content: flex-start;
    }
  }
}
