.dropdown-custom {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  span.label {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      margin-left: 0.6rem;
      fill: $gray-86;

      &:hover ~ div {
        display: block;
      }
    }
    > div {
      position: relative;
    }
  }

  > span:first-child {
    color: $red-09;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3;
    width: 100%;
    margin: 0.6rem 0 0.4rem;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;

    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    + select {
      border-color: $red-09;

      + svg {
        fill: $red-09;
      }
    }
  }

  > select {
    /* display: none; */
    appearance: none;
    width: 100%;

    border: 1px solid $gray-07;
    height: 5.4rem;
    color: #373636;
    background: $white-00;
    padding: 1.6rem;
    padding-right: 1.6rem;
    transition: 180ms ease-in-out;
    font-size: 1.9em;

    :focus {
      box-shadow: none;
    }

    ~ svg {
      fill: $gray-86;
      position: absolute;
      right: 1.5rem;
      top: 3.5rem;
      transition: default;
    }

    + span {
      font-size: 1.3rem;
      margin-bottom: 0.3rem;
      text-transform: uppercase;
    }
  }

  > button {
    position: absolute;
    padding: 0;
    right: 1.5rem;
    top: 3.6rem;
    z-index: 2;

    :hover {
      transform: scale(1.1);
    }
  }
}

select.dropdown-form {
  height: 3.5rem;
  padding: 0;
  padding-left: 0.5em;
  border: 0;

  svg {
    top: 1em;
    right: 1em;
  }
}
