.avatarInput {
  display: flex;
  align-self: center;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  label {
    cursor: pointer;
    max-width: 110px;
    max-height: 110px;

    img {
      height: 110px;
      width: 110px;
      border-radius: 50%;
      background: $gray-01;
      object-fit: cover;
    }
    input {
      display: none;
    }
  }
}
