.dash-table {
  .top {
    padding: 1.5em;
    border-bottom: 1px solid #f3efef;

    p {
      margin-bottom: 0 !important;
    }

    p.title {
      @include respond-to(handhelds) {
        text-align: center;
        margin-bottom: 1em;
      }
      @include respond-to(wide-screens) {
        text-align: left;
        margin-bottom: 0;
      }
    }
    .filters {
      @include font-source($font-book, 0.9em, $gray-86, 400, 1em);

      .btn.dropdown-toggle {
        font-size: 1em;
        border-radius: unset;
      }
      .dropdown-menu {
        border-radius: 0;
        border: 0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      }
      .dropdown-item:active {
        background-color: $gray-86;
      }
      .dropdown-menu a {
        font-size: 0.9em;
      }
      p {
        display: inline-block;
      }
    }
    .search {
      .form-inline .input-group {
        width: 100%;
      }
      input,
      button {
        background-color: #f7f7f7;
        border: none;
        border-radius: unset;
      }
    }
  }

  .input {
    border-left: 7px solid $green-32;
  }
  .output {
    border-left: 7px solid $red-09;
  }

  .footer {
    border-top: 1px solid #f3efef;
    padding: 1em 0;
    a {
      font-size: 0.9em;
    }
    p {
      @include font-source($font-medium, 1em, $gray-86, 400, 1em);
      margin-bottom: 0;

      span {
        @include font-source($font-book, 0.95em, $gray-86, 400, 1em);
      }
    }
  }
}
