form {
  select {
    border-radius: unset !important;
    border: 1px solid $gray-07;
    height: 3em;
    appearance: none;
    background-image: url(../../../assets/static/images/icons/arrow_down.png);
    background-position: center right 0.9375rem;
    background-repeat: no-repeat;
  }
}
input.select-full {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
