@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
}
@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
}
@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
}
@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
}
