/*-- Buttons --*/
@mixin button($background, $color) {
  background-color: $background;
  color: $color;

  // min-height: 3.4375rem;
  width: 253px;
  // font-size: 2rem;
  border-radius: 3px;
  border: none;
  font-family: $font-book;
  margin-bottom: 1em;
}

/*-- Align --*/
@mixin display-flex($direction, $justifyContent, $alignItems) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

/*-- Font --*/
@mixin font-source(
  $font: false,
  $size: false,
  $colour: false,
  $weight: false,
  $lh: false,
  $ta: false
) {
  @if $font {
    font-family: $font;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ta {
    text-align: $ta;
  }
}
//@include font-source($font, $size, $colour, $weight, $line-height:, $text-align)

/*-- Media --*/
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}
//@include respond-to(wide-screens) { }
