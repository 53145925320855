.title-pages {
  margin-bottom: 2em;

  h2 {
    // @include font-source($font-book, 3em, $gray-86, 400, 1.2em, center);

    // @include respond-to(wide-screens) {
    //   font-size: 4.5em;
    // }
    text-align: center;
    // margin-bottom: 0.5em;

    span {
      font-family: $font-black;
    }
  }
  h3 {
    @include font-source($font-book, 2em, $gray-86, 400, 1.5em, center);
    @include respond-to(wide-screens) {
      font-size: 2.5em;
    }
  }
  p {
    @include font-source($font-book, 1.5em, $gray-86, 400, 0.9em, center);
  }
}
