form {
  p {
    margin: 0;
  }

  /* -- Label -- */
  label {
    @include font-source($font-book, 15px, $gray-78, 400, 1.2, left);
    margin-bottom: 8px;
  }

  /* -- User Terms -- */
  .user-terms {
    p {
      margin: 0 !important;
    }
  }

  h3 {
    @include font-source($font-book, none, $gray-78, 400, 1.5em, left);
  }

  p.description {
    @include font-source($font-book, 15px, $gray-78, 400, 1.5em, center);
  }

  p {
    @include font-source($font-book, 15px, $gray-78, 400, 1.5em, left);
  }

  #formContainer {
    padding: 30px 30px 0px;
    @media (max-width: 768px) {
      padding: 15px 15px 0px;
    }
  }
}

.input-field {
  height: 53px;
  padding: 2px 15px;
}

.input-field-username {
  height: 50px;
}
