.container-flex {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 5em;
  margin-bottom: 5em;

  @include respond-to(wide-screens) {
    display: flex;
  }
}
