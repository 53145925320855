.uploadedOptions {
  display: flex;
  align-items: flex-end;

  @media (max-width: 568px) {
    & > a {
      width: auto;
      
      & > svg {
        width: 21px;
      }
    }
  }
}
