.message {
  display: none;
  position: absolute;
  background: $white-00;
  color: $gray-39;
  // font-size: 1.2rem;
  min-width: 20rem;
  right: 0.5rem;
  top: 2rem;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
}
