.tooltip-custom {
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    width: 13.125rem;

    border-radius: 3px;
    box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
    background-color: $white-00;
    color: $gray-39;
    font-size: 0.75rem;
    text-align: justify;
    padding: 0.75rem 0.625rem;
    margin: 0.75rem;
    word-wrap: break-word;

    position: absolute;
    z-index: 2;

    .position--left {
      right: 0;
    }

    .position--right {
      left: 0;
    }

    .position--top {
      bottom: 0;
    }

    .position--bottom {
      top: 0;
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
}
