form {
  a {
    @include font-source($font-book, 1em, $blue-00, 400, 1.5em, center);
    text-decoration: underline;
  }

  a.link-gray {
    color: $gray-86 !important;
  }
}
