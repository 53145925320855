.daterangepicker {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  width: auto;
}

.daterangepicker .ranges ul {
  padding: 4px;
  width: 175px;
}

.daterangepicker .ranges ul li {
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 1px;
}

.daterangepicker .ranges ul li:hover {
  background-color: #f1f9ff;
}

.daterangepicker .ranges ul li.active {
  background-color: #44adf8;
}

.daterangepicker .calendar-table th {
  font-weight: 500;
}

.table-condensed td.active {
  background-color: #44adf8;
}

.table-condensed td.active:hover {
  background-color: #44adf8;
}
