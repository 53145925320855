// html {
//   // width: 100vw; //bug scroll horizontal
//   // font-size: 62.5%; /*10px*/
//   // box-sizing: border-box;

//   // @media only screen and (max-width: 97em) {
//   //   font-size: 60%;
//   // }

//   // @media only screen and (max-width: 80em) {
//   //   font-size: 57.5%;
//   // }

//   // @media only screen and (max-width: 37.5em) {
//   //   font-size: 55%;
//   // }
// }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
body {
  font-family: $font-medium !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #f6fafe;
  color: $gray-86;
  font-size: 0.875em; //14px
}
body,
input,
textarea {
  font-family: $font-book;
  box-sizing: inherit;
  box-shadow: none;
}
a,
button {
  outline: none;
}

a.bh-upgrade:hover,
a.bh-invite:hover {
  text-decoration: none;
  color: inherit;
}

/* Font Sizes */
h1 {
  font-size: 18px;
}
ul {
  padding: 0;
  list-style: none;
}
a {
  color: $gray-86;
}
img {
  max-width: 100%;
}

#products-menu-container .product-desc {
  display: none;
}
#products-menu-container .product-logo {
  height: 34px !important;
}

#products-menu-container .logo-container {
  margin-bottom: 0 !important;
}

#menu-alboom-pay #products-menu-container .menu-wrapper .menu-container {
  z-index: unset;
}

#products-menu-container .flag {
  right: 0px !important;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  #menu-alboom-pay #products-menu-container .menu-wrapper .menu-container {
    z-index: 1;
  }

  #menu-alboom-pay #products-menu-container.collapsed {
    .userInfo {
      display: block;
    }
  }
}

#reportrange {
  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between;
  }
}
