@import "bootstrap/scss/bootstrap.scss";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import '@alboom/boom-header/src/scss/boom-header.scss';

/* -- Utilities -- */
@import "./utilities/variables";
@import "./utilities/mixins";

/* -- Base -- */
@import "./base/fonts";
@import "./base/public";

/* -- Title -- */
@import "./components/title";

/* -- Componentes -- */

/* -- V2 -- */
@import "./components/container";
@import "./components/content";
@import "./components/layout";
/* -- FIM V2 -- */

@import "./components/cardWhite";
@import "./components/buttons";
@import "./components/cards";
@import "./components/checkbox";
@import "./components/dropdown";
@import "./components/inputRadio";
@import "./components/table";
@import "./components/tooltip";
@import "./components/inputText";
@import "./components/links";
@import "./components/avatarInput";
@import "./components/message";
@import "./components/toaster";

@import "./components/select";

/* -- Layout -- */
@import "./layout/form";

@import "./layout/navbar";
@import "./layout/sidebar";
@import "./layout/optionsWebsite";
@import "./layout/uploadedOptions";
