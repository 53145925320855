.content {
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  padding: 1em;
  width: auto;
  margin: 0 10px;

  @include respond-to(wide-screens) {
    width: 640px;
    padding: 30px 50px;
  }
}
