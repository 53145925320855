.input-text {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  &.error {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 15px;

    &.error {
      margin-bottom: 0;
    }
  }

  span.label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    color: #282828;
    font-size: 14px;
    font-weight: 400;
    svg {
      margin-left: 0.6rem;
      // fill: $gray-07;

      &:hover ~ div {
        display: block;
      }
    }
    > div {
      position: relative;
    }
  }

  span.input-maxlength {
    display: block;
    text-align: right;
    padding: 5px 10px 0 0;
    font-size: 12px;
    color: #535353;
    font-weight: 400;
  }

  > span:not(.input-maxlength):first-child {
    color: $red-09;
    font-weight: 400;
    line-height: 1.3;
    width: 100%;
    margin: 5px 0 15px;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;
    text-transform: initial;

    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    + input {
      border-color: $red-09;

      + svg {
        fill: $red-09;
      }
    }
  }

  > input {
    width: 100%;
    border: 1px solid $gray-07;
    transition: 180ms ease-in-out;
    border-radius: 3px;
    font-size: 15px;

    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
    }
    // font-size: 1.9em;
    &:read-only {
      background: $gray-07;
    }

    :focus {
      box-shadow: none;
    }

    ~ svg {
      position: absolute;
      margin-top: -2px;
      margin-left: 11px;
      left: 0;
      top: 50%;
      transition: default;
      width: 30px;
      height: 30px;
    }

    // + span {
    //   // font-size: 1.3rem;
    //   // margin-bottom: 0.3rem;
    //   // text-transform: uppercase;
    // }
  }

  > button {
    position: absolute;
    padding: 0;
    right: 1.5rem;
    top: 3.6rem;
    z-index: 2;
  }
}
