.cards-dash {
  margin-top: 2em;
  margin-bottom: 2em;
  @include font-source($font-book, 0.8em, $white-00, 400, 1.5em);

  .card {
    padding: 2em 0;
    border: none;
    border-radius: unset;
    height: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    img {
      display: inline-block;
      width: 80%;
      margin: 0 2em;
    }
    p {
      margin-bottom: 1em;
    }

    .price {
      font-size: 2.3em;

      span {
        font-size: 0.5em;
      }
    }
  }
}
